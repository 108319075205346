export const ROOT_PATH = '/'
export const TERMS_PATH = '/terms'
export const PRIVACY_PATH = '/privacy'

export const USER_LOGIN_PATH = 'users/login'
export const USER_LOGOUT_PATH = 'users/logout'
export const USER_SIGN_UP_PATH = 'users/signup'
export const FAVORITE_JOB_PATH = '/favorites/jobs'
export const EDIT_PROFILE_PATH = '/profiles/edit'
export const CANDIDATE_SIGN_UP_PATH = '/signup/new'
export const CANDIDATE_LOGIN_PATH = '/candidate/login'
export const SEARCH_PATH = '/jobs?direct=true'
export const AGENT_SEARCH_PATH = '/jobs?agent=true'
export const LIST_CONDITION_SEARCH_PATH = '/search/list_conditions'
export const TEASER_PATH = '/teaser'
export const WELCOME_GUIDE_PATH = '/candidates/welcome_guides'

// get jobs auth from Agent site
export const V1_AGENT_AUTHORIZED_PATH = '/v1/agent/authorized'
export const V1_GET_JOBS_AGENT = '/v1/agent/jobs'

// Agent consideration
export const V1_AGENT_CONSIDERATION_PATH = '/v1/agent/consideration'

// jobs search
export const V1_JOBS_PATH = '/v1/jobs'
export const V1_PROPOSITON_PATH = '/v1/propositions'

// v1/address_with_zip_code
export const V1_ADDRESS_WITH_ZIP_CODE = '/v1/address_with_code'

// v1/candidates
export const V1_BASIC_INFORMATION_PATH = '/v1/candidates/basic_information'
export const V1_JOB_SEARCH_CONDITION = '/v1/candidates/search_condition'
export const V1_WORK_HISTORY_PATH = '/v1/candidates/work_history'
export const V1_CANDIDATE_EXPERIENCE_PATH = '/v1/candidates/experience'
export const V1_JOB_SEARCH_PATH = '/v1/candidates/search_condition'
export const V1_SAVE_CONDITION_JOB_SEARCH = '/v1/job_search_condition'
export const V1_OFFER_REPORT_PATH = '/v1/candidates/offer_report'
export const V1_SEARCH_JOB_PATH = '/v1/candidates/search_condition/search'
export const V1_PROFILE_PATH = '/v1/candidates/profile'
export const V1_CANDIDATES_VERIFY_ACCOUNT = '/v1/candidates/register'
export const V1_CANDIDATES_SAVE_ACCOUNT = '/v1/candidates/register'
export const V1_CANDIDATES_VERIFY_TOKEN = '/v1/candidates/verify_token'
export const V1_CANDIDATE_RESET_PASSWORD = '/v1/candidates/reset_password'
export const V1_CANDIDATE_RESEND_MAIL = '/v1/candidates/registers/resend_email'

export const V1_RESUME_EDUCATION_PATH = '/v1/candidates/resume_education'
export const V1_RESUME_SKILL_PATH = '/v1/candidates/resume_skills'
export const V1_RESUME_INFORMATION_PATH = '/v1/candidates/resume_information'
export const V1_RESUME_OCCUPATION_PATH = '/v1/candidates/resume_occupation'
export const V1_RESUME_OPTIONAL_INFORMATION_PATH = '/v1/candidates/resume_optional_information'
export const V1_RESUME_ANALYSIS_PATH = '/v1/candidates/resume_analysis'

export const V1_SIGN_UP_PATH = '/v1/candidates/sign_up'
export const V1_SESSION_PATH = '/v1/candidates/sessions'
export const V1_COMPLETED_SIGN_UP_PATH = '/v1/candidates/completed_signup'
export const V1_BLOCKED_COMPANIES_PATH = '/v1/blocked_company'

// v1/masters
export const V1_OCCUPATION_PATH = '/v1/masters/occupations'
export const V1_COUNTRY_PATH = '/v1/masters/countries'
export const V1_PREFECTURE_PATH = '/v1/masters/prefectures'
export const V1_INCOME_PATH = '/v1/masters/incomes'
export const V1_EMPLOYMENT_TYPE_PATH = '/v1/masters/employment_types'
export const V1_SCHOOL_CATEGORY_PATH = '/v1/masters/school_categories'
export const V1_SKILL_PATH = '/v1/masters/skills'
export const V1_INDUSTRIES_PATH = '/v1/masters/industries'
export const V1_LANGUAGE_LEVEL_PATH = '/v1/masters/language_levels'
export const V1_LANGUAGE_PATH = '/v1/masters/languages'
export const V1_GRADUATION_CLASSIFICATION_PATH = '/v1/masters/graduation_classifications'
export const V1_REGISTRATION_PATH = '/v1/masters/registration'
export const V1_JOB_FEATURES_PATH = '/v1/masters/job_features'
export const V1_CITY_PATH = '/v1/masters/cities'
export const V1_EXPERIENCE_PATH = '/v1/masters/experiences'

// v1/company_accounts
export const V1_COMPANY_ACCOUNTS = '/v1/company_accounts'

// auth0 callback url
export const LOGIN_SESSION_CALLBACK = '/sessions'
